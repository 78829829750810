import React from "react"
import { FaEnvelope } from 'react-icons/fa';
import { FaPhone } from 'react-icons/fa';
import { FaFacebook, FaTwitter } from "react-icons/fa/index"
import Link from 'gatsby-link'

const toggleBurger = () => {
  let burgerIcon = document.getElementById('js-burger-mobile');
  let dropMenu = document.getElementById('js-nav-menu-mobile');
  let blackOverlay = document.getElementById('js-black-overlay');

  burgerIcon.classList.toggle('is-active')
  dropMenu.classList.toggle('is-active')
  blackOverlay.classList.toggle('is-active')
}

export default () => (
  <header>
    {/* Mobile*/}
    <div className="top-bar is-hidden-tablet mobile-header">
      <div className="container">
        <div className="columns is-marginless is-mobile is-vertical-center has-text-centered-mobile">
          <div className="column is-2">
              <a href="mailto:info@caledoniantreeservices.com" aria-label="Email Caledonian tree services">
                <FaEnvelope />
              </a>
              <a href="tel:01505 872929" aria-label="Phone Caledonian tree services">
                <FaPhone />
              </a>
          </div>
          <div className="column is-8">
            <div className="container logo">
              <Link to="/"><span className="caledonain">Caledonian</span><span className="tree"> Tree Services</span></Link>
            </div>
          </div>
          <div className="column is-2">
            <div tabIndex="0" className="navbar-burger" id="js-burger-mobile" onClick={ toggleBurger }>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Desktop  and Tablet */}
    <div className="top-bar is-hidden-mobile">
      <div className="container top-bar-container">
        <div className="columns is-marginless">
          <div className="column is-four-fifths-tablet">
            <div className="columns">
              <div className="column is-5-widescreen is-6-desktop is-7-tablet has-text-centered-mobile">
                <a aria-label="Click here to contact us via email." href="mailto:info@caledoniantreeservices.com">
                  <FaEnvelope />&nbsp;info@caledoniantreeservices.com
                </a>
              </div>
              <div className="column has-text-centered-mobile">
                <a aria-label="Click here to give us a call." href="tel:01505 872929">
                  <FaPhone />&nbsp;01505 872929
                </a>
              </div>
            </div>
          </div>
          <div className="column tablet social-icons has-text-right">
            <a aria-label="Click here to visit our Facebook page." href="https://www.facebook.com/caledoniantreeservices/">
              <FaFacebook />
            </a>
            <a aria-label="Click here to visit our Twitter page." href="https://twitter.com/CaledonianTree">
              <FaTwitter />
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
)
