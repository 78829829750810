import React from 'react'
import Link from 'gatsby-link'
import { FaFacebook, FaTwitter, FaTimes } from "react-icons/fa"

export default () => (
  <div>
    <nav className="navbar-menu-mobile is-hidden-tablet" id="js-nav-menu-mobile">
      <div className="navbar-start">
        <ul>
          <li>
            <Link to="/" >Home</Link>
          </li>
          <li className="no-border">
            <h4>Domestic Services</h4>
            <ul>
              <li>
                <Link to="/tree-surgery/" >Tree Surgery</Link>
              </li>
              <li>
                <Link to="/hedge-maintenance" >Hedge Maintenance</Link>
              </li>
            </ul>
          </li>

          <li className="no-border">
            <h4>Commercial Services</h4>
            <ul>
              <li>
                <Link to="/woodland-maintenance/" >Woodland Maintenance</Link>
              </li>
              <li>
                <Link to="/site-clearance/" >Site Clearance</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/health-and-safety">Health &amp; Safety</Link>
          </li>
          <li>
            <Link to="/portfolio/">Portfolio</Link>
          </li>
          <li>
            <Link to="/contact/">Contact</Link>
          </li>
        </ul>
        <div className="column social-icons is-vertical-center">
          <a aria-label="Click here to visit our Facebook page." href="https://www.facebook.com/caledoniantreeservices/">
            <FaFacebook />
          </a>
          <a aria-label="Click here to visit our Twitter page." href="https://twitter.com/CaledonianTree">
            <FaTwitter />
          </a>
          <div className="close-nav-mobile is-vertical-center"><FaTimes />Close </div>
        </div>
    </div>
  </nav>
</div>
)