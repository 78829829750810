import React from 'react'

export default () => (
  <footer className="section">
    <div className="container content-container">
      <div className="columns is-mobile has-text-centered-mobile">
        <div className="column">
          <p>© copyright caledonian tree services { new Date().getFullYear() }</p>
        </div>
      </div>
    </div>
</footer>
)