import React from "react"
import Header from "../layout/header"
import NavbarMobile from "../layout/navbar-mobile"
import Footer from "../layout/footer"

export default ({ children }) => (
  <div>
    <Header/>
    <div className="navbar-container-mobile">
      <NavbarMobile/>
    </div>
    <div className="black-overlay" id="js-black-overlay" />
    <div>
      {children}
    </div>
    <Footer/>
  </div>
)
