import React from 'react'
import Link from 'gatsby-link'

export default () => (
  <div className="navbar-container">
    <div className="container">
      <nav className="is-hidden-mobile">
        <ul className="has-text-centered">
          <li>
            <Link to="/" >Home</Link>
          </li>
          <li className="no-border dropdown">
            <h4 className="is-vertical-center">Domestic Services</h4>
            <ul className="dropdown-content has-text-left">
              <li>
                <Link to="/tree-surgery/" >Tree Surgery</Link>
              </li>
              <li>
                <Link to="/hedge-maintenance/" >Hedge Maintenance</Link>
              </li>
            </ul>
          </li>

          <li className="no-border dropdown">
            <h4>Commercial Services</h4>
            <ul className="dropdown-content has-text-left">
              <li>
                <Link to="/woodland-maintenance/" >Woodland Maintenance</Link>
              </li>
              <li>
                <Link to="/site-clearance/" >Site Clearance</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/health-and-safety/">Health &amp; Safety</Link>
          </li>
          <li>
            <Link to="/portfolio/">Portfolio</Link>
          </li>
          <li>
            <Link to="/contact/">Contact</Link>
          </li>
        </ul>
      </nav>
    </div>
</div>
)