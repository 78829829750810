import React, { Component } from "react"
import Img from "gatsby-image"

class Hero extends Component {
  render() {
    return <section className="hero is-vertical-center">
      <div className="is-hidden-tablet has-text-centered">
        <Img Tag="section"
             fluid={this.props.heroMobile}
             fadeIn={false}
             backgroundColor={`#fff`}
             style={{
               position: "absolute",
               left: 0,
               top: 0,
               width: "100%",
               height: "100%"
             }}></Img>
        {this.props.logoMobile && (
          <div className="logo-hero-mobile is-vertical-center">
            <figure className="image is-x75x75">
              <Img fixed={this.props.logoMobile} />
            </figure>
            <span>
              <h2 className="hero-text strap-line" dangerouslySetInnerHTML={{ __html: this.props.straplineMobile }} />
            </span>
          </div>
        )}
        {!this.props.logoMobile && (
            <h2 className="hero-text strap-line is-mobile" dangerouslySetInnerHTML={{ __html: this.props.straplineMobile }} />
        )}
      </div>
      <div className="is-hidden-mobile">
        <Img Tag="section"
             fluid={this.props.hero}
             fadeIn={false}
             backgroundColor={`#fff`}
             style={{
               position: "absolute",
               left: 0,
               top: 0,
               width: "100%",
               height: "100%"
             }}
        ></Img>
        <div className="container logo hero-text has-text-centered">
          <div className="columns is-marginless">
            <div className="column is-one-fifth has-text-right">
              <figure className="image is-x150x150">
                <Img fixed={this.props.logo} />
              </figure>
            </div>
            <div className="column is-three-fifths-desktop">
              <h2>
                <span className="caledonain">Caledonian</span><span className="tree"> Tree Services</span>
              </h2>
              <h3 className="strap-line is-mobile has-text-centered" dangerouslySetInnerHTML={{ __html: this.props.strapline }} />
            </div>
            <div className="column is-one-fifth ">
              <figure className="image is-x150x150">
                <Img fixed={this.props.arb} />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  }
}

export default Hero
